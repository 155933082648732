.ant-collapse {
  border: none;
  background: white;
  padding: 15px;
  .ant-collapse-item {
    background: #265ba6;
    border-radius: 8px;
    // position: sticky;
    // top: 0;
    .ant-collapse-header {
      font-size: 25px;
      color: white;
      font-weight: 400;
      .ant-collapse-expand-icon {
        height: 40px;
      }
      .ant-collapse-arrow {
        font-size: 17px;
      }
    }
  }
  .ant-collapse-item:last-child {
    border-radius: 8px;
  }
}

// tabs css
.ant-tabs .ant-tabs-tab {
  color: black;
  font-size: 20px;
  font-weight: 500;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #265ba6;
}
.ant-tabs .ant-tabs-tab:hover {
  color: #265ba6;
}
.ant-tabs .ant-tabs-ink-bar {
  background: #265ba6;
}
.ant-tabs > .ant-tabs-nav {
  position: sticky;
  top: 0px;
  // margin: 45px 0 !important;
  z-index: 2;
  margin: 0;
  background: #f1f5f9;
}
.ant-tabs-top > .ant-tabs-nav::before {
  border: none;
}

.cutsom-full {
  height: auto !important;
  padding: 0 24px !important;
}

.task-card {
  cursor: pointer;

  .ant-card-body {
    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.05) !important;
    padding: 15px 20px;
  }
  .ant-avatar-circle {
    margin: 0;
  }

  .ant-card-meta-detail {
    display: flex;
    align-items: center;
  }
  .color-greeen-parent {
    transition: 0.5s ease-in-out;
  }
  // .color-greeen-parent:hover .color-greeen {
  //   color: green !important;
  // }
}

.active-card {
  background-color: gray; /* Change this to your desired active card background color */
}

.task-button,
.task-button:hover,
.task-button:focus {
  background-color: transparent !important;
  color: black !important;
  border: 1px solid black !important;
  box-shadow: none !important;
}

.task-button:disabled {
  cursor: not-allowed;
  background-color: #f5f5f5 !important;
}
.task-input,
.task-input:hover,
.task-input:focus {
  // border-bottom: 1px solid grey !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  padding: 0 0;
}
.ant-form-item1 {
  .ant-form-item {
    margin: auto;
  }
  .ant-form-item-control-input {
    min-height: auto;
  }
}

.margin-type {
  .ant-typography {
    margin-bottom: 0 !important;
    // height: 25px;
    text-align: justify;
    background-color: white !important;
  }
  .ant-typography-edit-content {
    background-color: white !important;
    padding: 0% !important;
    margin: 0%;
    margin-top: -10px;
    margin-bottom: -10px !important;
    display: flex;
    width: 100% !important;
    // text-align: justify;
    // height: 0;
    align-items: center;
  }
}
.hauto {
  .ant-select-selector {
    // height: 42px !important ;
    padding: 10px;

    .ant-select-selection-item {
      margin: auto;
    }
  }
}

.ant-input-number:hover .ant-input-number-handler-wrap {
  opacity: 0;
}
.ant-picker-ok {
  .ant-btn {
    min-width: auto !important;
  }
}

.grabbable {
  // cursor: move; /* fallback if grab cursor is unsupported */
  cursor: all-scroll !important;
  cursor: -moz-all-scroll;
  cursor: -webkit-all-scroll;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:focus,
.grabbable:active {
  cursor: all-scroll !important;
  cursor: -moz-all-scroll !important;
  cursor: -webkit-all-scroll !important;
}
/* Custom CSS to style the Select options as open */
.custom-select-container .ant-select-dropdown {
  display: block !important;
  opacity: 1 !important;
  pointer-events: auto !important;
  transform: translateY(0) !important;
}
